.userlist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ulc-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 18px;
    align-items: center;
    word-wrap: break-word;
    flex-grow: 0;  
    justify-content: center;  
  }
  