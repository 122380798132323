.uspc-row-header,
.uspc-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.uspc-row-header{
    font-weight: bold;
}

.uspc-sum-header,
.uspc-sum{
    width: 150px;
    text-align: center;
    margin: 5px;
}

.uspc-sum{
    color: #4ab15b;
    font-weight: bold;
}

.uspc-team{
    display: flex;
    width: 150px;
    flex-direction: column;
    justify-content: center;
    height: 102px;
    cursor: pointer;
    color: blue;
    :hover{
        color: darkgrey;
    }

    @media screen and (max-width: 800px){
        font-size: 4vw;
        :hover{
            color: unset;
        }
       
}
}

.uspc-icon{
    display: flex;
    width: 50px;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    cursor: pointer;
    :hover{
        color: darkgrey;
    }
    
}

.uspc-ren-hidden{
    display: none;
    
}

.uspc-teamOrPersonal{
    display: flex;
    width: 150px;
    flex-direction: column;
    justify-content: center;
    height: 102px;
}

h2{
    @media screen and(max-width: 800px) {
        font-size: 4vw;
    }
}

.uspc-sum-header,
.uspc-sum{
    @media screen and (max-width: 800px){
        font-size: 4.5vw;
        
    }
}

.uspc-status{
    width: 100px;
    cursor: pointer;
    &:hover{
        color: pink;
    }
    @media screen and (max-width: 800px){
        &:hover{
            color: unset;
        }
    }
}

.uspc-status-selected{
    width: 100px;
    color: palevioletred;
}