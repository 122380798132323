// Main container: initialize the flex, direction is row
.lc-row {
  display: flex;           
  flex-direction: row;     
  flex-grow: 0;            
  flex-wrap: wrap;        
  width: 100%;
  padding: 20px 5px;
  text-align: left;
  border-bottom: 1px solid darkgrey;

  @media screen and (max-width: 800px){
    margin: 5px;
    padding: 0;
  }

}

.lc-header {
  font-weight: bold;

}

 // Define the widths: play around with these to get a best fit.
 $count-width: 20px;
 $date-width: 80px;
 $event-width: 130px;
 $name-width: 120px;
 $email-width: 150px;
 $phone-width: 100px;
 $referral-width: 100px;
 $remark-width: 120px;
 $icon-width: 20px;

// Text based cells: grow, truncate and show ellipsis when there is not enough space
.lc-count,
.lc-date,
.lc-event,
.lc-name,
.lc-email,
.lc-phone,
.lc-referral,
.lc-remark {
  flex-grow: 1;            -webkit-flex-grow: 1;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  padding-right: 15px;
  padding-top: 7px;
  text-align: center;
  font-size: 18px;
  min-height: 40px;
  word-wrap: break-word;

  @media screen and (max-width: 800px){
    text-align: left;
    padding: 2px;
    margin: 0px;
    font-size: 4vw;
    min-height: 20px;
    
  }
}

// Fix the minimum width of the leaf level cells
.lc-count{
  width: $count-width;
  font-size: 16px;

  @media screen and (max-width: 800px){
    width: 30%;
    height: 10px;
  }
}

.lc-date {
  width: $date-width;
  @media screen and (max-width: 800px){
    width: 70%;
    color: #68350c;
    font-weight: bold;
  }
}

.lc-event {
  width: $event-width;
  font-size: 16px;

  @media screen and (max-width: 800px){
    width: 100%;
    overflow: hidden;
    word-wrap: normal;
    text-align: center;
    font-size: 3.2vw;
    
  }
}

.lc-name {
  width: $name-width;
  @media screen and (max-width: 800px){
    width: 50%;
    font-weight: bold;
    color: #0c66bb;

  }
}

.lc-email {
  width: $email-width;
  @media screen and (max-width: 800px){
    width: 50%;
    text-overflow: ellipsis;
    word-wrap: normal;
    text-align: right;
    font-weight: bold;
    color: #0c66bb;
  }
}

.lc-phone {
  width: $phone-width;
  @media screen and (max-width: 800px){
    width: 60%;
    font-weight: bold;
    color: #0c66bb;
  }
}

.lc-referral {
  width: $referral-width;
  @media screen and (max-width: 800px){
    width: 40%;
    text-align: right;
  }
}

.lc-remark {
  width: $remark-width;

  @media screen and (max-width: 800px){
    width: 70%;
  }
}

.lc-icon {

  width: $icon-width;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  padding-top: 10px;

  @media screen and (max-width: 800px){
    width: 10%;
    text-align: right;
  }
}


  