.leads-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.lp-not-sign-in {
  text-align: center;
  width: 100%;
}

.lp-filter {
  display: flex;
  flex-direction: row;
  min-height: 50px;
  cursor: pointer;
  text-align: center;

  :hover {
    opacity: 50%;
  }
  
  @media screen and (max-width: 800px){
    :hover{
      opacity: unset;
    }
  }
}

.lp-icon-container,
.lp-referral,
.lp-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80px;
  border: 1px solid grey;
  margin: 15px;
  height: 80px;

  @media screen and (max-width: 800px){
    padding: 0;
    margin: 5px;;
  }
  
}

.lp-icon{
  width: 50px;
  height: 50px;
}