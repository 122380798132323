.upc-sign-in{
    width: 100%;
    text-align: center;
}

.upc-table{
    width: 100%;
    text-align: center;
}

.upc-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.upc-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100px;
    font-size: 40px;
    cursor: pointer;
    &:hover{
        color: pink;
    }
}

.upc-loading{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
}