
.ccpc-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.ccpc-row-1,
.ccpc-row-0{
    width: 100%;
    justify-content: center;
   
}

.ccpc-row-1{
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 800px){
        width: 100%;
    }
}


.ccpc-item{
    width: 100%;
    margin: 10px 0px;
}

.ccpc-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.ccpc-add-project{
    border: 1px solid grey;
    border-radius: 10px;
    margin: 10px 0;
    width: 200px;
    cursor: pointer;
}

.ccpc-projects{
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    width: auto;
    padding: 5px;
    margin: 2px 5px;
}