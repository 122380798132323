
.ppc-table{
    width: 100%;
    text-align: center;
}

.ppc-row-1,
.ppc-row-0{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.ppc-row-1{
    border-bottom: 1px solid grey;
}

.ppc-item{
    width: 150px;
    margin: 0;
    padding: 5px;
}