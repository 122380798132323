.tcpc-table{
    position: fixed;
    left: 10px;
    top: 5px;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid black;
    background-color: white;
    z-index: 5;
    align-items: center;

    @media screen and (max-width: 800px) {
        position: fixed;
        left: 10px;
        top: 5px;
    }
}

.tcpc-row-1,
.tcpc-row-0{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.tcpc-row-border {
    border-bottom: solid grey 1px;
    padding-bottom: 5px;
}

.tcpc-item{
    width: 50%;
    margin: 5px;
    text-align: center;
}

.tcpc-button{
    border: 1px;
    border-style: solid;
    padding: 5px;
    cursor: pointer;
}

.tcpc-form{
    width: 100%;
}

.tcpc-form-input{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    background: none;
    background-color: white;
    font-size: 18px;
    display: block;
    width: 60%;
    border: none;
    border-radius: 0;

    @media screen and (max-width: 800px){
        width: 100%;
    }
}