.crc-row,
.crc-row-header{
    display: flex;           
    flex-direction: row;     
    flex-grow: 0;            
    flex-wrap: wrap;        
    width: 100%;
    padding: 20px 5px;
    text-align: left;
    border-bottom: 1px solid darkgrey;
}

.crc-row-header{
    font-weight: bold;
}

.crc-item{
    flex-grow: 1;            -webkit-flex-grow: 1;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    padding-right: 15px;
    padding-top: 7px;
    text-align: center;
    font-size: 18px;
    min-height: 40px;
    width: 100px;
}