.cc-row-header,
.cc-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    border-bottom: 1px solid grey;

}

.cc-item-header,
.cc-item{
    width: 200px;
    margin: 10px 5px;
    flex-grow: 1;
    @media screen and (max-width: 800px) {
       margin: 0px;
       padding: 3px 3px;
       font-size: 3.8vw;
}
}

.cc-item-header{
    font-weight: bold;
}

.cc-description{
    font-weight: bold;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.cc-conversionDate,
.cc-endDate,
.cc-startDate{
    @media screen and (max-width: 800px) {
        width: 33%;
    }
}

.cc-projects{
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.cc-salesValue{
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

