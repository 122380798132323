.lac-table{
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;
}

.lac-row,
.lac-row-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 18px;
    align-items: center;
    word-wrap: break-word;
    flex-grow: 0;    
}

.lac-row-header{
    font-weight: bold;
    @media screen and (max-width:800px){
        display: none;
    }
        
}

.lac-delete,
.lac-action-label,
.lac-action-date,
.lac-action,
.lac-date,
.lac-eventTitle,
.lac-name,
.lac-email,
.lac-phone,
.lac-affiliateId,
.lac-remark,
.lac-actionLog {
    word-wrap: break-word;
    text-align: left;
    margin: 10px 10px;

}

$date-width : 150px;
$eventTitle-width : 200px;
$name-width : 150px;
$email-width : 200px;
$phone-width : 150px;
$affiliateId-width : 120px;
$remark-width : 150px;
$action-width : 300px;
$delete-width: 50px;

.lac-action.label,
.lac-action-date,
.lac-date {
    width: $date-width;
}

.lac-eventTitle {
    width: $eventTitle-width;
    @media screen and (max-width:800px){
        width: 100%;
    }
}

.lac-name {
    width: $name-width;
}

.lac-email {
    width: $email-width;
    @media screen and (max-width:800px){
        width: 100%;
    }
}

.lac-phone {
    width: $phone-width;
    @media screen and (max-width:800px){
        width: 100%;
    }
}

.lac-affiliateId {
    width: $affiliateId-width;
    @media screen and (max-width:800px){
        width: 100%;
    }
}

.lac-remark {
    width: $remark-width;
    @media screen and (max-width:800px){
        width: 100%;
    }
}

.lac-action {
    width: $action-width;
}

.lac-delete {
    width: $delete-width;
    font-size: 15px;
    color: red;
    cursor: pointer;
    
    &:hover {
        opacity: 50%;
    }
}