
.pcpc-table{
    width: 100%;
    text-align: center;
}

.pcpc-row-1,
.pcpc-row-0{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.pcpc-row-1{
    border-bottom: 1px solid grey;
}

.pcpc-item{
    width: 50%;
    margin: 15px;
}

.pcpc-form{
    width: 400px;
}