.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat';

    h1 {
      margin: 0px;
      padding: 5px;
    }
  }
  
  .form {
    width: 380px;
    display: flex;
    flex-direction: column;
  }

  .hpc-password,
  .hpc-link,
  .hpc-projects,
  .hpc-leads,
  .hpc-users,
  .hpc-create,
  .hpc-total,
  .hpc-sales-page,
  .hpc-sales {
    width: 350px;
    height: 100px;
    font-size: 30px;
    font-weight: bolder;
    margin: 5px;
    border-bottom: 1px solid grey;
    text-align: center;
    font-family: 'Montserrat';
    
    div{
      margin: 10px;
      cursor: pointer;
      &:hover{
      color: deeppink;
    }
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      &:hover{
        opacity: unset;
      }
    }
  }

  .hpc-create{
    div{
      color: #91550e;
    }
  }

.hpc-header {
    text-align: center;
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 20px;
    color: #4277c0;

    @media screen and (max-width: 800px){
      font-size: 6vw;
      font-weight: bolder;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

.hpc-subtitle{
  padding: 0 5px 10px 10px;
  font-size: 14px;
  font-weight: normal;
  cursor: default !important;
  &:hover{
    color: unset !important;

  }

}

.hpc-password{
  font-size: 18px;
  font-weight: bold;
  margin: 15px;
  height: 60px;
  :hover{
    color: deeppink;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    &:hover{
      color: unset;
    }
}
}

.hpc-sign-in{
  font-size: 18px;
  text-align: center;
  margin: 20px 0px;
  border-bottom: 1px solid grey;
  padding: 15px 0px;
  @media screen and (max-width: 800px) {
    margin: 0px;
    padding: 5px 0px
  }
  div{
    margin: 10px 0px;
    @media screen and (max-width: 800px){
      width: 100%;
      font-size: 4vw;
    }
  }
}

.hpc-new{
  font-size: 15px;
  color: deeppink;
  @media screen and (max-width: 800px){
    font-size: 3vw;
  }
}

