
.src-table{
    width: 100%;
    text-align: center;
    padding: 0 15px;

    h1{
        margin: 5px;
    }
}

.src-row-1,
.src-row-0{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.src-row-1{
    border-bottom: 1px solid grey;
}

.src-item{
    width: 500px;
    margin: 8px 0;
    text-align: left;
}

.src-send-email{
    width: 150px;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 5px;
    &:hover{
        color: limegreen;
    }
}

.src-form{
    width: 500px;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.src-project{
    color: '#063f6b'; 
    text-align: left;
    width: 500px;
    div{
        padding: 3px 0;
    }
    @media screen and (max-width: 800px) {
    font-size: 3.8vw;
        
    }
}