.header-container{
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid grey;
  padding-bottom: 25px;
  z-index: 5;

  @media screen and (max-width: 800px) {
    height: 60px;
    padding: 5px 0px;
    margin-bottom: 5px;
    justify-content: space-between;
  }
}


.logo-container{
  height: 100%;
  width: 300px;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 200px;
    padding: 0;
    flex-direction: row;
    align-items: center;
}
}

.options-container{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    width: 80%;
    display: none;
  }
}

.options-container-always {
  height: 100%;
  display: flex;
  align-items: center;
}

.option-link{
  padding: 5px 5px;
  cursor: pointer;
  text-align: right;
}

.options-container-mobile {
    width: 80%;
    position: fixed;
    width: 180px;
    height: 0px;
    top: 80px;
    right: 13px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: flex-start;
    background-color: white;
    border: 1px solid grey;
    opacity: 0;
    transition: height 0.3s, opacity 0.3s linear;

}

.option-link-mobile {
  padding: 5px 5px;
  cursor: pointer;
  text-align: right;
  &:hover{
    color: deeppink;
  }
  @media screen and (max-width: 800px) {
    padding: 5px 5px;
    cursor: pointer;
    text-align: right;
    &:hover{
      color: unset;
    }
  }
}

.hc-logo{
  width:150px;
  align-self: start;
  @media screen and (max-width: 800px){
      width: 120px;
      padding-top: 0px;
  }
}

.hc-toggle{
  width: 50px;
  align-self: flex-end;
  padding-top: 15px !important;
}

.header-back{
  position: fixed;
  top: 110px;
  left: 45px;
  cursor: pointer;
  @media screen and (max-width: 800px){
    top: 85px;
    left: 10px;
  }
}