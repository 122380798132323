.scp-table{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scp-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scp-form{
    width: 400px;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

    @media screen and (max-width: 800px){
        width: 100%;
    }
}

.scp-remark,
.scp-date{
    background: none;
    background-color: white;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    margin: 25px 0;
    cursor: pointer;
}

.scp-ren,
.scp-project {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    margin: 25px 0;
    color: grey;
    cursor: pointer;
}

.scp-ren-row{
    display: flex;
    flex-direction: row;
}

.scp-ren-item{
    width: 70%;
    
}

.scp-ren-percentage{
    width: 30%;
    padding-left: 10px;
}

.scp-ren-row-button{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.scp-ren-button{
    display: flex;
    margin: 0 5px;
    cursor: pointer;
}

.scp-delete{
    cursor: pointer;
    color: red;
    margin: 25px;
    &:hover{
        opacity: 50%;
    }
}