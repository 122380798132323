.sc-row-border,
.sc-row-header,
.sc-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;
    justify-content: center;

    @media screen and (max-width: 800px) {
        text-align: left;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
    }
}


.sc-row-header{
    font-weight: bold;
    border-top: 1px solid grey;
    
}

.sc-row-border{
    border-top: 1px solid grey;
}

$width-saleId: 60px;
$width-date: 100px;
$width-projectName: 100px;
$width-unitNumber: 100px;
$width-spaPrice: 100px;
$width-netPrice: 100px;
$width-buyerName: 100px;
$width-package: 100px;
$width-renName: 100px;
$width-renPercentage: 100px;
$width-remark: 100px;
$width-number: 30px;

.sc-update,
.sc-status,
.sc-date-hidden,
.sc-projectName-hidden,
.sc-unitNumber-hidden,
.sc-netPrice-hidden,
.sc-buyerName-hidden,
.sc-saleId-hidden,
.sc-netPriceForRen,
.sc-commForRen,
.sc-saleId,
.sc-date,
.sc-projectName,
.sc-unitNumber,
.sc-spaPrice,
.sc-netPrice,
.sc-buyerName,
.sc-package,
.sc-renName,
.sc-renPercentage,
.sc-remark{
    margin: 3px 7px;
    word-wrap: break-word;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 800px){
        display: block;
        width: 25%;
        margin: 0;
        padding: 3px 5px;
        font-size: 3.6vw;
        text-align: right;
    }
}

.sc-netPrice-hidden,
.sc-netPrice{
    width: $width-netPrice;
   
}

.sc-commForRen,
.sc-renName,
.sc-renPercentage{
    color: #2e7c95;
    font-weight: bold;
}

.sc-saleId-hidden,
.sc-saleId{
    width: $width-saleId;
    @media screen and (max-width: 800px){
        text-align: left;
        font-weight: bold;
    }
}


.sc-saleId,
.sc-unitNumber,
.sc-renName{
    @media screen and (max-width: 800px){
        width: 33%;
        text-align: left;
       
        
    }
}

.sc-renPercentage,
.sc-netPrice,
.sc-date-hidden,
.sc-date{
    width: $width-date;
    @media screen and (max-width: 800px){
        width: 27%;
    }
}

.sc-netPriceForRen,
.sc-projectName,
.sc-buyerName,
.sc-commForRen{
    @media screen and (max-width: 800px){
        width: 40%;
    }
}

.sc-status-hidden,
.sc-status{
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    width: 300px;
    margin: 10px 7px;
    @media screen and (max-width: 800px){
        width: 100%;
        justify-content: flex-end;
        margin: 0;
    }
}

.sc-status-hidden,
.sc-date-hidden,
.sc-projectName-hidden,
.sc-unitNumber-hidden,
.sc-netPrice-hidden,
.sc-buyerName-hidden,
.sc-saleId-hidden{
    @media screen and (max-width: 800px){
        width: 0px;
        margin: 0;
        display: none;
    }
}

.sc-report,
.sc-update,
.sc-status-item{
    border: 1px solid #ff8206;
    background-color: #ff8206;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    margin: 0 5px;
    width: 100px;
    padding: 3px 5px;
    padding-top: 4px;
    cursor: pointer;
    @media screen and (max-width: 800px){
        width: 18%;
        text-align: center;
        margin: 0px 0px;
        margin-left: 5px;
        font-size: 2.8vw;
        padding: 3px;
    }
}

.sc-status-Done,
.sc-status-Converted{
    border: 1px solid #27b711;
    background-color: #27b711;
}

.sc-status-Cancelled{
    border: 1px solid #c01008;
    background-color: #c01008;
}

.sc-update{
    border: 1px solid #3b65ba;
    background-color: #3b65ba;
}

.sc-report{
    border: 1px solid #648f54;
    background-color: #648f54;
}

//For Total Sales and Comm Display
.sc-Booked,
.sc-Converted,
.sc-projectNameForTotalSales,
.sc-renNameForTotalSales,
.sc-number,
.sc-totalComm,
.sc-totalSales{
    margin: 2px 7px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (max-width: 800px){
        margin: 0;
        font-size: 3.8vw;
        padding: 2px 2px;
    }
}

.sc-number{
    width: 50px;
    text-align: center;
    @media screen and (max-width: 800px){
        width: 8%;
        font-size: 3.3vw;
    }
}

.sc-projectNameForTotalSales,
.sc-renNameForTotalSales{
    width: 150px;
    color: #2e7c95;
    font-weight: bold;
    @media screen and (max-width: 800px){
        width: 32%;
    }
}

.sc-Booked,
.sc-Converted,
.sc-totalComm,
.sc-totalSales{
    width: 150px;
    text-align: right;
    font-family: 'Roboto';
    @media screen and (max-width: 800px){
        width: 25%;
    }
}

.sc-header{
    white-space: normal;
    word-wrap: normal;
    font-weight: bolder;
    @media screen and (max-width: 800px){
        font-size: 3.5vw;
    }
}

.sc-saleId-Item{
    cursor: pointer;
    color: lightseagreen;
}


