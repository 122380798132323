.ucpc-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}

.ucpc-header{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.ucpc-row{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.ucpc-form{
   display: flex;
}