
.utspc-table{
    width: 100%;
    text-align: center;
}

.utspc-row-1,
.utspc-row-0{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.utspc-row-1{
    border-bottom: 1px solid grey;
}

.utspc-item{
    width: 50px;
    margin: 15px 5px;
    font-size: 28px;
    cursor: pointer;
    @media screen and (max-width: 800px) {
        margin: 4px 0px;
    }
}

.utspc-separator,
.utspc-filter-status,
.utspc-filter{
    width: 100px;
    margin: 5px 5px;
    font-size: 28px;
    cursor: pointer;
    @media screen and (max-width: 800px) {
        margin: 4px 0px;
        margin-top: 2px;
        font-size: 4.5vw;
    }
}

.utspc-separator{
    width: 20px;
}

.utspc-filter-status{
    font-size: 24px;
    @media screen and (max-width: 800px) {
        margin: 4px 0px;
        margin-top: 2px;
        font-size: 4vw;
    }
}

.utspc-date{
    width: 100px;
    margin: 15px 5px;
    font-size: 24px;
    @media screen and (max-width: 800px) {
        margin: 4px 0px;
    }
}

.utspc-total{
    width: 100%;
    margin: 0px 5px;
    font-size: 28px;
    color: rgb(26, 86, 88);
    @media screen and (max-width: 800px) {
        margin: 0px 0px;
        width: 100%;
        font-size: 5vw;
        margin-top: 2px;
    }
}