.stc-row-border,
.stc-row-header,
.stc-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;
    justify-content: center;

    @media screen and (max-width: 800px) {
        text-align: left;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
    }
}


.stc-row-header{
    font-weight: bold;
    border-top: 1px solid grey;
    
}

.stc-row-border{
    border-top: 1px solid grey;
}

$width-saleId: 60px;
$width-date: 100px;
$width-projectName: 100px;
$width-unitNumber: 100px;
$width-spaPrice: 100px;
$width-netPrice: 100px;
$width-buyerName: 100px;
$width-package: 100px;
$width-renName: 100px;
$width-renPercentage: 100px;
$width-remark: 100px;
$width-number: 30px;

.stc-update,
.stc-status,
.stc-netPriceForRen,
.stc-commForRen,
.stc-saleId,
.stc-date,
.stc-projectName,
.stc-unitNumber,
.stc-spaPrice,
.stc-netPrice,
.stc-buyerName,
.stc-package,
.stc-renName,
.stc-renPercentage,
.stc-remark{
    margin: 10px 7px;
    word-wrap: break-word;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 800px){
        display: block;
        width: 25%;
        margin: 0;
        padding: 3px 5px;
        font-size: 3.6vw;
        text-align: right;
    }
}

.stc-netPrice-hidden,
.stc-netPrice{
    width: $width-netPrice;
   
}

.stc-commForRen,
.stc-renName,
.stc-renPercentage{
    color: #2e7c95;
    font-weight: bold;
}

.stc-saleId-hidden,
.stc-saleId{
    width: $width-saleId;
    @media screen and (max-width: 800px){
        text-align: left;
        font-weight: bold;
    }
}


.stc-saleId,
.stc-unitNumber,
.stc-renName{
    @media screen and (max-width: 800px){
        width: 33%;
        text-align: left;
       
        
    }
}

.stc-renPercentage,
.stc-netPrice,
.stc-date-hidden,
.stc-date{
    width: $width-date;
    @media screen and (max-width: 800px){
        width: 27%;
    }
}

.stc-netPriceForRen,
.stc-projectName,
.stc-buyerName,
.stc-commForRen{
    @media screen and (max-width: 800px){
        width: 40%;
    }
}

.stc-status-hidden,
.stc-status{
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    width: 300px;
    margin: 0px;
    @media screen and (max-width: 800px){
        width: 100%;
        justify-content: flex-end;
    }
}

.stc-report,
.stc-update,
.stc-status-item{
    border: 1px solid #ff8206;
    background-color: #ff8206;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    margin: 5px 5px;
    width: 100px;
    padding: 3px;
    padding-top: 4px;
    cursor: pointer;
    @media screen and (max-width: 800px){
        width: 18%;
        text-align: center;
        margin: 5px 0px;
        margin-left: 5px;
        font-size: 2.8vw;
        padding: 3px;
    }
}

.stc-status-Done,
.stc-status-Converted{
    border: 1px solid #27b711;
    background-color: #27b711;
}

.stc-status-Cancelled{
    border: 1px solid #c01008;
    background-color: #c01008;
}

.stc-update{
    border: 1px solid #3b65ba;
    background-color: #3b65ba;
}

.stc-report{
    border: 1px solid #648f54;
    background-color: #648f54;
}

//For Total Sales and Comm Display
.stc-renNameForTotalSales,
.stc-number,
.stc-totalComm,
.stc-totalSales{
    margin: 10px 7px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (max-width: 800px){
        margin: 0;
        font-size: 3.8vw;
        padding: 2px 2px;
    }
}

.stc-number{
    width: 50px;
    text-align: center;
    @media screen and (max-width: 800px){
        width: 10%;
        font-size: 3.3vw;
    }
}

.stc-renNameForTotalSales{
    width: 150px;
    color: #2e7c95;
    font-weight: bold;
    @media screen and (max-width: 800px){
        width: 32%;
    }
}

.stc-totalComm,
.stc-totalSales{
    width: 150px;
    text-align: right;
    font-family: 'Roboto';
    @media screen and (max-width: 800px){
        width: 23%;
    }
}

.stc-header{
    white-space: normal;
    word-wrap: normal;
    font-weight: bolder;
    @media screen and (max-width: 800px){
        font-size: 3.5vw;
    }
}

.stc-saleId-Item{
    cursor: pointer;
    color: lightseagreen;
}


