.lead-form-container {
    position: absolute;
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    z-index: 5;
    right: 115px;
    top: auto;
    overflow: scroll;
    align-items: center;

    .lf-close,
    .lf-date,
    .lf-event,
    .lf-name,
    .lf-email,
    .lf-phone,
    .lf-remark{
        width: 100%;
        text-align: center;
        margin: 5px 5px;
        word-wrap: break-word; 
    }

    .lf-close {
        text-align: right;
        margin: 0px;
        cursor: pointer;
    }

    
    @media screen and (max-width: 800px) {
        position: fixed;
        top: 5px;
        left: 10px;
        
    }
}