
.csc-table{
    width: 100%;
    text-align: center;
}

.csc-row-1,
.csc-row-0{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.csc-row-1{
    border-bottom: 1px solid grey;
}

.csc-item{
    width: 150px;
    margin: 15px;
}

.csc-form{
    width: 400px;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

    @media screen and (max-width: 800px){
        width: 100%;
    }
}

.csc-position
.csc-rate,
.csc-date{
    background: none;
    background-color: white;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    height: 100px;
}