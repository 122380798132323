.stcc-row-header,
.stcc-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stcc-row-header{
    font-weight: bold;
}

.stcc-projectNameForTotalSales,
.stcc-number,
.stcc-renNameForTotalSales,
.stcc-totalSales,
.stcc-barChart{
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    
    @media screen and (max-width: 800px){
        font-size: 3vw;
        padding: 1px 5px;
    }
}

.stcc-number{
    width: 50px;
    text-align: left;
    padding-left: 0;
    @media screen and (max-width: 800px){
        width: 6%;
    }
}

.stcc-projectNameForTotalSales,
.stcc-renNameForTotalSales{
    width: 150px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2e7c95;
    font-weight: bold;
    @media screen and (max-width: 800px){
        width: 30%;
        padding-right: 0px;
    }
}

.stcc-totalSales{
    width: 100px;
    text-align: right;
    @media screen and (max-width: 800px){
        width: 22%;
    }
}

.stcc-barChart{
    width: 350px;
    text-align: left;
    color: #3366cc;
    padding-left: 20px;
    @media screen and (max-width: 800px){
        width: 52%;
        padding-left: 8px;
        font-size: 2.2vw;
    }
}