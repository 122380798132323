.tsu-table{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tsu-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tsu-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

    @media screen and (max-width: 800px){
        width: 50%;
    }
}

.tsu-content{

    padding: 20px;
}

.tsu-verification{
    width: 20%;
    padding: 5px;
    align-content: center;
    margin: 5px;
    border: 1px;
    border-color: gray;
    border-style: solid;
    height: 50px;
    cursor: pointer;
    @media screen and (max-width: 800px){
        width: 50%;
    }
}