  .uc-row,
  .uc-row-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 18px;
    align-items: center;
    word-wrap: break-word;
    flex-grow: 0;  
    justify-content: center;  

    @media screen and (max-width: 800px) {
        justify-content: space-between;
        padding: 5px;
    }
  }
  
  .uc-row-header{
    font-weight: bold;
  }
    
    
    $uc-name-width: 160px;
    $uc-email-width: 200px;
    $uc-referralID-width: 130px;
    $uc-delete-width: 70px;
    $uc-width-numberOfSales: 100px;
    $uc-width-userId: 80px;
    $uc-width-location: 100px;

    .uc-userId,
    .uc-item,
    .uc-name,
    .uc-email,
    .uc-referralID {
      margin: 0;
      padding: 10px;
      word-wrap: break-word;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      

      @media screen and (max-width: 800px) {
        display: block;
        width: 25%;
        margin: 0;
        padding: 5px;
        font-size: 3.8vw;
      }
    }

    .uc-numberOfSales {
      width: $uc-width-numberOfSales;
      text-align: center;
      @media screen and (max-width: 800px) {
        width: 15%;
        text-align: right;
      }
    }

    .uc-userId {
      width: $uc-width-userId;
      @media screen and (max-width: 800px) {
        width: 20%;
        text-align: left;
        font-weight: bold;
      }
    }
    
    .uc-displayName{
      width: $uc-name-width;
      cursor: pointer;
      a {
        color: blue;
      }
      @media screen and (max-width: 800px) {
        width: 30%;
        text-align: left;
        font-weight: bold;
      }
    }

    .uc-location {
      width: $uc-width-location;
      @media screen and (max-width: 800px) {
        width: 20%;
        text-align: right;
      }
    }

    .uc-email{
      width: $uc-email-width;
      overflow-wrap: break-word;
      word-break: break-all;
      display: inline-block;
      @media screen and (max-width: 800px) {
        width: 50%;
        text-align: right;
      }
    }

    .uc-phoneNumber{
      @media screen and (max-width: 800px) {
        width: 30%;
        text-align: left;
      }
    }

    .uc-immediateUpline{
      @media screen and (max-width: 800px) {
        width: 30%;
        text-align: left;
      }
    }

    .uc-referralID{
      width: $uc-referralID-width;
    }

    .uc-delete {
      width: $uc-delete-width;
      cursor: pointer;
      text-align: center;
      @media screen and (max-width: 800px) {
        width: 100%;
        text-align: right;
      }
    }

    .uc-item-header{
      white-space: normal;
      word-wrap: normal;
      font-weight: bolder;
    }

    

    

  