.tpc-table {
  width: 100%;
  text-align: center;
}

.tpc-row-1,
.tpc-row-0 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.tpc-row-1 {
  border-bottom: 1px solid grey;
  justify-content: flex-start;
}

.tpc-item {
  width: 5%;
  padding: 5px;
  padding-top: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.tpc-item1 {
  width: 30%;
  @media screen and (max-width: 800px) {
    width: 40%;
  }
}

.tpc-item2 {
  width: 60%;
}

.tpc-item3 {
  width: 100%;
  text-align: left;
  margin-top: 10px;
}

.tpc-item4 {
  width: 30%;
  text-align: left;
  @media screen and (max-width: 800px) {
    width: 30%;
    font-size: 2.5vw;
    padding: 3px;
  }
}

.tpc-button {
  width: 20%;
  border: 1px;
  border-style: solid;
  padding: 5px;
  padding-top: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 5px;
  @media screen and (max-width: 800px) {
    width: 30%;
    padding-top: 7px;
    font-size: 3.5vw;
  }
}

.tpc-image {
  width: 95%;
}

.tpc-author,
.tpc-category {
  height: 25px;
  background-color: #ff8206;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  width: 30%;
  padding: 3px 5px;
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 30%;
    font-size: 2.5vw;
    padding: 5px 0;
  }
}

.tpc-seen {
  width: 25%;
  font-size: 15px;
  @media screen and (max-width: 800px) {
    width: 30%;
    font-size: 2vw;
    padding: 5px 0;
  }
}

.tpc-delete {
  cursor: pointer;
  color: red;
  margin: 25px;
}

.tpc-search {
  width: 55%;
  height: 60px;
  margin-bottom: 15px;
  margin-top: 0;
  padding-bottom: 20px;
}

.tpc-blue {
  background-color: cadetblue;
}

.tpc-row-left {
  justify-content: left;
}

.tpc-row-mt-15 {
  margin-top: 15px;
}

.tpc-pointer {
  cursor: pointer;
}
