
.slc-row-header,
.slc-row{
  cursor: pointer;
  justify-content: center;
}

.slc-row-header{
    font-weight: bold;
    cursor: default;
}