.lef-table{
    position: absolute;
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    z-index: 5;
    align-items: center;

    @media screen and (max-width:800px){
        left: 5px;
    }
    
    .lef-value,
    .lef-label{
        width: 100%;
        text-align: center;
        margin: 15px 0;
    }

    .lef-label{
        font-weight: bold;
    }

    .lef-close{
        width: 100%;
        text-align: right;
        cursor: pointer;
    }

}

