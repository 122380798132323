.iddc-table{
    position: absolute;
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid black;
    background-color: white;
    z-index: 5;
    align-items: center;

    @media screen and (max-width: 800px) {
        position: fixed;
        left: 10px;
        top: 5px;
    }
}

.iddc-row{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.iddc-close{
    width: 50px;
    cursor: pointer;
}

.iddc-search{
    display: flex;
    width: 100%;
   
}

.iddc-list{
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 0 5px;
}

.iddc-items{
    min-height: 35px;
    display: flex;
    text-align: center;
    cursor: pointer;

    &:hover{
        opacity: 60%;
    }
}

