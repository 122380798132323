.leadlist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .leads {
      width: 100%;
      display: flex;
      flex-direction: column;

    }
  }

  