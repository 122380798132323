.spc-fetching{
    width: 100%;
    text-align: center;
}

.spc-table{
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.spc-row-top,
.spc-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.spc-row-top{
    @media screen and (max-width:800px){
        height: 50px;
    }
}

.spc-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.spc-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 20px;
    height: 60px;
    font-size: 30firepx;

    &:hover{
        color: palevioletred;
    }
}

.spc-search{
    height: 60px;
    margin-bottom: 15px;
    margin-top: 0;
    padding-bottom: 20px;
}

.spc-download{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding-right: 5px;
    text-align: left;
    height: 60px;
    padding-top: 7px;
}

.spc-quarter{
    font-size: 24px;
    padding-top: 10px;
    align-items: center;
    cursor: pointer;
    &:hover{
        color: deeppink ;
    }
    @media screen and (max-width: 800px) {
        font-size: 5vw;
        &:hover{
            color: unset;
        }
    }
}