.ue-table{
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 50px;
    align-items: left;

    .ue-row{
        width:90%;
        display: flex;
        flex-direction: row;
        min-height: 50px;
        margin: 15px 15px;
        border-bottom: 1px solid darkgrey;
        text-align: left;
        flex-wrap: wrap;
        justify-content: center;    
        word-wrap: break-word;

        @media screen and (max-width: 800px) {
            justify-content: left;
            .ue-icon{
                position: absolute;
                right: 35px
            }
        }
    } 

    .ue-label{
        width: 150px;
        font-weight: bold;
    }

    .ue-value{
        width: 300px;
        margin-left: 20px;
    }

    .ue-icon{
        width: 20px;
        cursor: pointer;
        text-align: right;
    }

}
