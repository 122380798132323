.pc-row,
.pc-row-header{
    display: flex;           
    flex-direction: row;     
    flex-wrap: wrap;   
    justify-content: center;     
    width: 100%;
    padding: 5px 5px;
    text-align: left;
    border-bottom: 1px solid darkgrey;
    @media screen  and (max-width: 800px){
       
    }
}

.pc-row-header{
    font-weight: bold;
}

.pc-item{
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    padding-right: 15px;
    padding-top: 7px;
    text-align: center;
    font-size: 18px;
    width: 200px;

    @media screen  and (max-width: 800px){
        font-size: 4vw;
        padding: 5px;
    }
}

.pc-header{
    white-space: normal;
    word-wrap: normal;
    font-weight: bolder;
}

.pc-location{
    width: 150px;
}

.pc-projectId{
    font-weight: bold;
    width: 50px;
}

.pc-location,
.pc-projectId{
    text-align: left;
    @media screen  and (max-width: 800px){
        width: 25%;
    }
}

.pc-createdAt{
    width: 150px;
}
.pc-developer,
.pc-createdAt{
    @media screen  and (max-width: 800px){
        width: 30%;
    }
}

.pc-commStructureId{
    color: rgb(117, 36, 77);
}

.pc-projectName{
        width: 250px ;
        font-weight: bold;
        color: rgb(34, 103, 116);
        text-align: left;
}

.pc-projectName,
.pc-commStructureId{
    @media screen  and (max-width: 800px){
        width: 45%;
        text-align: right;
    }
}


